* {
  box-sizing: border-box;
  font-family: "Questrial", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 auto;
}

.home {
  background: $home-image;
  background-attachment: fixed;
  background-size: cover;
  color: set-text-color($primary-color);
  height: 110vh !important;

  font-family: "Questrial", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
  border-bottom: 1rem solid black;

  .layer {
    background: rgba($primary-color, $background-opacity);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110vh;
  }
}

// Headings
h1,
h2,
h3 {
  margin: 0;
  // font-weight: 800 !important;
  font-family: "Questrial", Tahoma, Geneva, Verdana, sans-serif;

  &.lg-heading {
    font-size: 8rem;
    // font-weight: 900;
    text-align: center;
    font-family: "Questrial", Tahoma, Geneva, Verdana, sans-serif;
  }

  &.sm-heading {
    margin-bottom: 2rem;
    padding: 0.2rem 1rem;
    text-align: center;
  }
}

a {
  color: #fff;
  text-decoration: none;
}

header {
  position: fixed;
  z-index: 2;
  width: 100%;
  font-family: "Questrial", Tahoma, Geneva, Verdana, sans-serif;
}

.text-secondary {
  color: $secondary-color;
}

.fas {
  color: $secondary-color;
  padding-bottom: 1rem;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;

  .section {
    height: 100vh;
    width: 100%;
  }

  .section-sm {
    height: 50vh;
    width: 100%;
    padding: 5rem;
  }

  .projects-app {
    padding-top: 4rem;
    background: $primary-color;
    border-top: 10px solid black;
    border-bottom: 10px solid black;
    height: 115vh;

    .page {
      overflow: hidden;
      width: 100%;
      height: 500px;
    }

    .projects-title {
      margin-bottom: 5rem;
      color: $color-five;
      font-size: 3rem;
    }

    .function-button {
      color: white;
      font-weight: 100;
      height: 4rem;
      width: 4rem;
      text-align: center;
      font-family: Tahoma, sans-serif !important;
      background-color: rgba($secondary-color, $alpha: 0.5);
      border: 1px solid $color-five;
      margin: 1rem 4rem;
      border-radius: 100px;
      transition: all 100ms ease-in-out;
      border: 1px solid black;

      &:hover {
        transform: scale(1.1);
      }
    }
    .btn-container {
      display: flex;
      justify-content: space-around;
      align-content: flex-end;
      width: 400px;
      padding-top: 1rem;

      .card-btn {
        width: 40%;
        color: $color-four;
        font-size: 1.4rem;

        i {
          color: $secondary-color;
        }
      }
    }

    .card {
      transition: all 0.3s linear;
      cursor: pointer;
      padding: 25px;
      width: inherit !important;
      height: 40%;
      text-align: left;
      margin: 0 auto;

      &:hover {
        background-color: white;
      }

      img {
        max-width: calc(100%);
        margin: 0 auto;
        display: block;
        border: 1px solid black;
      }

      .test-email-text {
        font-weight: 900;
        padding-top: 2rem;
      }

      .details {
        position: relative;
        padding-top: 1rem;
      }
      .features {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          padding-left: 24px;
          margin-right: 10px;
          display: inline-block;
          span {
            display: none;
          }
        }
      }
    }

    /* #endregion */

    /* #region cards slider */
    .cards-slider-wrapper {
      display: flex;
      position: absolute;
      transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      .card {
        margin-left: 4rem;
        flex: 1;
        min-width: 375px;
        height: 450px;
        opacity: 0.7;
        transform: scale(0.8);
        box-shadow: none;
        background-color: white;
        transition: all 300ms linear, scale 500ms linear;
        .icon-container {
          display: flex;
          background: $color-four;
          justify-content: space-between;
          width: 100%;
        }
        .details {
          opacity: 0;
          transition: opacity 150ms linear;
        }
      }
    }

    $i: 0;
    @for $i from 0 through 5 {
      .cards-slider.active-slide-#{$i} #card-#{$i} {
        opacity: 1;
        height: 450px;

        transform: scale(1);

        background-color: white;
        z-index: 2;

        .details {
          opacity: 1;
        }
      }
    }

    .col {
      height: 400px;
      position: relative;
      &:before,
      &:after {
        content: "";
        display: block;
        width: 50%;
        height: 450px;
        background: linear-gradient(
          to right,
          rgba($primary-color, 0.2) 15%,
          rgba($primary-color, 0.2) 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      &:after {
        left: auto;
        right: 0;
        background: linear-gradient(
          to left,
          rgba($primary-color, 0.2) 15%,
          rgba($primary-color, 0.2) 100%
        );
      }
    }

    .cards-slider {
      position: relative;
      max-width: 500px;
      margin: 0 auto;

      // boder for active property
      &:after {
        content: "";
        display: block;
        margin-left: 4rem;
        width: 375px;
        height: 450px;
        border: 5px solid $secondary-color;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    /* #endregion */
  }

  .about {
    padding-top: 5rem;
    height: 105vh;

    .title {
      font-size: 3rem;
    }

    .qualities {
      display: flex;
      justify-content: space-around;
      padding-top: 5rem;
      max-width: 80%;

      .quality {
        display: flex;
        flex-direction: column;
        max-width: 20%;
      }

      p {
        font-size: 1.15rem;
      }

      h3 {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }

    .about-btm {
      display: flex;
      padding: 7rem 0;

      .bio {
        display: flex;
        flex-direction: column;
        width: 33%;
        font-size: 1.3rem;

        .portrait {
          width: 350px;
          height: 350px;
          background: url("../img/deejaySM.jpg");
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: solid 3px $secondary-color;
          margin-bottom: 1rem;
        }
      }

      .skills {
        width: 30%;

        .skill-item {
          padding-bottom: 1.5rem;

          h3 {
            font-size: 1rem;
          }

          .progress-bar {
            background: rgba($color: $primary-color, $alpha: 0.2);
            position: relative;
            display: block;
            height: 20px;
            border-radius: 30px;
            border: 1px solid rgba($primary-color, 0.3);
            box-shadow: inset 0 1px 2px rgba($primary-color, 0.8),
              0 1px rgba(white, 0.8);

            .filler {
              position: relative;
              vertical-align: middle;
              float: left;
              height: 19px;
              border-radius: 10px;
              overflow: hidden;
              background-color: $secondary-color;
              background-size: 100%;
              background-image: linear-gradient(
                to bottom,
                $secondary-color,
                $primary-color
              );
              animation: progress-anim-1 10s 1 forwards;
            }

            .filler:after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: "";
              background-size: 100%;
              background-image: linear-gradient(
                45deg,
                rgba(0, 0, 0, 0) 25%,
                $secondary-color,
                rgba(0, 0, 0, 0) 50%,
                $secondary-color,
                rgba(0, 0, 0, 0) 75%,
                $secondary-color,
                rgba(0, 0, 0, 0)
              );
              background-size: 30px 30px;
              opacity: 0.3;
              animation: progress-anim-1-after 5s infinite;
            }

            @-webkit-keyframes progress-anim-1 {
              0% {
                width: 0%;
              }
              100% {
                width: 100%;
              }
            }
            @keyframes progress-anim-1 {
              0% {
                width: 0%;
              }
              100% {
                width: 100%;
              }
            }
            @-webkit-keyframes progress-anim-1-after {
              0% {
                background-position: 0 100%;
              }
              100% {
                background-position: 100px 100%;
              }
              0% {
                background-position: 0 100%;
              }
            }
            @keyframes progress-anim-1-after {
              0% {
                background-position: 0 100%;
              }
              100% {
                background-position: 30px 100%;
              }
            }

            .html {
              max-width: 99%;
            }

            .css {
              max-width: 99%;
            }

            .javascript {
              max-width: 90%;
            }

            .react {
              max-width: 90%;
            }

            .java {
              max-width: 85%;
            }

            .node {
              max-width: 75%;
            }

            // @keyframes animate {
            //   0% {
            //     width: 0;
            //   }
            //   100% {
            //     width: 100%;
            //   }
            // }
          }
        }
      }
    }
  }

  .contact-title {
    padding-top: 5rem;
    font-size: 3rem;
  }

  #contact {
    background: $home-image;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .layer {
      background: rgba($primary-color, $background-opacity);
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
      height: 80vh;
    }

    .btn {
      color: white;
      font-weight: 200;
      font-family: Tahoma, sans-serif !important;
      background-color: rgba($secondary-color, $alpha: 0.9);
      border: 1px solid $color-five;
      transition: all 100ms ease-in-out;
      border: 1px solid black;

      &:hover {
        transform: scale(1.1);
      }
    }

    .flex-col {
      display: flex;
      flex-direction: column;
      align-items: center;

      label {
        font-size: 1.5rem;
      }

      input {
        width: 350px;
        margin-bottom: 2rem;
        text-justify: start;
      }
    }

    #message {
      height: 150px;
    }

    button {
      max-width: 100px;
    }
  }

  main {
    padding: 4rem;
    // height: 100%;
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20rem;

    .icons {
      margin-top: 10rem;
      @include easeOut;
      z-index: 1;

      a {
        padding: 2rem;
        @include easeOut;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
  .footer {
    background: $primary-color;
    width: 100%;
    height: 120px;
    padding-top: 2rem;
    border-top: 10px solid black;

    .icons {
      width: 20%;
      display: flex;
      justify-content: space-between;

      .fab {
        color: $secondary-color;
      }
    }

    .copyright {
      color: $color-four;
      padding-top: 1rem;
    }
  }

  .jumbotron {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: rgba(3, 0, 0, 0);
    color: $color-five;
    width: 100%;
    height: 80%;
    top: 20%;
    // right: 40px;

    h1 {
      color: rgba($color: $secondary-color, $alpha: 0.4);
      text-align: center;
      font-size: 12rem;
      font-weight: 500;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $color-five;
    }

    .btn {
      margin-top: 2rem;
      color: $color-five;
      font-weight: 200;
      font-family: Tahoma, sans-serif !important;
      background-color: rgba($secondary-color, $alpha: 0.3);
      border: 1px solid $color-five;
      transition: all 400ms ease-in-out;

      &:hover {
        background-color: rgba($secondary-color, $alpha: 0.9);
        border: 1px solid black;
        color: $color-five;
      }
    }

    .console-container {
      font-family: "Questrial";
      font-size: 1.8em;
      text-align: center;
      color: $color-four;
      margin-top: 2rem;
    }
    .console-underscore {
      display: inline-block;
      position: relative;
      top: -0.14em;
      left: 10px;
    }
  }
}
