.navbar-dark {
  background-color: rgba($color: $primary-color, $alpha: 0.8);
  display: flex !important;
  justify-content: space-between !important;

  .navbar-brand {
    position: absolute;
    left: 1%;
    color: $color-four !important;
  }
}

.navbar {
  display: flex !important;
  justify-content: space-between !important;
}
.navbar-nav {
  display: flex !important;
  justify-content: space-between;
  width: 300px !important;

  .link-container {
    // margin-left: 150%;
    display: flex !important;
    text-align: right;

    .nav-link {
      color: $color-four !important;

      a {
        cursor: pointer;
      }
    }
  }
}
