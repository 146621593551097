$primary-color: #202321;
$secondary-color: #7a52aa;
$color-three: #a25643;
$color-four: #bea65d;
$color-five: #c1ccc7;

$show-home-image: true;
$home-image: url(../img/background.jpg);
$background-opacity: 0.8;

@mixin easeOut {
  transition: all 0.5s ease-out;
}

// Set Text Color
@function set-text-color($color) {
  @if (lightness($color) > 40) {
    @return #000;
  } @else {
    @return #fff;
  }
}

// Media Query Mixins
@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  @media screen and (min-width: 1171px) {
    @content;
  }
}
