@include mediaMd {
  .link-container {
    width: 250px;
  }

  .display-3 {
    font-size: 1000% !important;
  }

  .console-text {
    font-size: 1.5rem;
    margin-bottom: 12rem !important;
  }

  .btn-primary {
    transform: scale(1.5);
    margin-top: 8rem !important;
    // position: absolute;
    // left: 42.5%;
    // top: 50%;

    &:hover {
      transform: scale(1.6);
    }
  }

  .about {
    .qualities {
      p {
        display: none;
      }
    }

    .skills {
      display: none;
    }

    .about-btm {
      padding-top: 2rem !important;
    }

    .bio {
      display: flex;
      flex-direction: row !important;

      width: 100% !important;

      .portrait {
        transform: scale(0.75);
        float: left;
      }

      .myBio {
        width: 250px;
        padding-top: 2rem;
        margin-top: 4rem;
      }
    }
  }
}

@include mediaSm {
  .projects-app {
    .projects-title {
      margin-bottom: 1rem !important;
    }
  }
  .cards-slider-wrapper {
    .card {
      margin-left: 0 !important;
      min-width: 370px !important;
    }
  }

  .cards-slider {
    max-width: 370px !important;
    &:after {
      margin-left: 0 !important;
      width: 370px !important;
    }
  }

  .display-3 {
    font-size: 5rem !important;
  }

  .console-text {
    font-size: 1.2rem;
  }

  .link-container {
    float: right !important;
  }

  .about {
    margin-bottom: 6rem;

    .qualities {
      padding: 0 !important;
      width: 100% !important;
      justify-content: space-between !important;
      .quality {
        margin-left: 1rem;

        h3 {
          font-size: 1rem !important;
        }
      }
    }

    .bio {
      flex-direction: column !important;

      .myBio {
        width: 300px !important;
        padding: 0 !important;
      }
    }

    //this is a comment

    .portrait {
      border-radius: 55px !important;
      width: 70% !important;
      margin-top: 1rem !important;
    }
  }

  .section-sm {
    padding: 0 !important;
  }

  a.navbar-brand {
    display: none;
  }

  .icons {
    display: flex !important;
    justify-content: space-around !important;

    .fab {
      margin: 0 1rem;
    }
  }
}

@include mediaMd {
  .about {
    height: 110vh !important;

    .qualities {
      padding-top: 1rem !important;
      h3 {
        font-size: 1.2rem !important;
      }
    }

    .bio {
      flex-direction: column !important;
    }

    .myBio {
      width: 400px !important;
      padding: 0 !important;
      margin-top: 1.5rem !important;
    }
    .portrait {
      border-radius: 15px !important;
      transform: scale(1.1) !important;
      margin-top: 1rem !important;
    }
  }

  .contact {
    height: 57vh !important;
    width: 100% !important;
  }

  .icons {
    display: flex !important;
    justify-content: space-around !important;

    .fab {
      margin: 0 1rem;
    }
  }
}

@include mediaLg {
  .navbar {
  }

  .link-container {
    width: 100%;
  }

  .about {
    .qualities {
      padding-top: 2rem !important;
    }

    .about-btm {
      padding-top: 2rem !important;
    }

    .myBio {
      width: 250%;
      padding-top: 2rem;
    }
  }
}
